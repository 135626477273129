import auth0 from 'auth0-js';
import AUTH_CONFIG from '../config/auth0';
import history from './History';

export default class Auth {
  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.userProfile = null;

    this.auth0 = new auth0.WebAuth({
      domain: AUTH_CONFIG.domain,
      clientID: AUTH_CONFIG.clientId,
      redirectUri: AUTH_CONFIG.callbackUrl,
      audience: `https://${AUTH_CONFIG.domain}/userinfo`,
      responseType: 'token id_token',
      scope: 'openid profile roles groups read:permissions'
    });
  }

  login() {
    //alert(AUTH_CONFIG.callbackUrl);
    this.auth0.authorize();
  }

  handleAuthentication() {
    
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        this.go("/dashboards/analytics");
      } else if (err) {
        
      }
    });
  }
  setSession2(hash) {
    let arrays = hash.split('&');
    arrays.forEach(element => {
      let elems = element.split('=');
      if (elems[0] === '#/access_token') {
        localStorage.setItem('access_token', elems[1]);
      } else if (elems[0] === 'id_token') {
        localStorage.setItem('id_token', elems[1]);
      } else if (elems[0] === 'expires_in') {
        const expiresAt = JSON.stringify(
          parseInt(elems[1]) + new Date().getTime()
        );
        localStorage.setItem('expires_at', expiresAt);
      }
    });
    this.isAuthenticated = true;
    this.go('/dashboards/analytics');
  }

  setSession(authResult) {
    // Set the time that the access token will expire at
    const exp = authResult.expiresIn * 1000;
    const expiresAt = JSON.stringify(exp + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
    //  alert(expiresAt);
    //  alert(expiresAt-new Date().getTime());
    // navigate to the home route
    history.replace('/');
  }

  getAccessToken() {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      // navigate to the home route
      this.go('/login');
       this.go('/login');
      //throw new Error('No access token found');

    }
    return accessToken;
  }

  getIdToken() {
    const idToken = localStorage.getItem('id_token');
    if (!idToken) {
      return null;
    }
    return idToken;
  }

  getProfile(cb) {
    const accessToken = this.getAccessToken();
    this.auth0.client.userInfo(accessToken, (err, profile) => {
      if (profile) {
        this.userProfile = profile;
      }
      cb(err, profile);
    });
  }

  logout() {
    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    this.userProfile = null;
    // navigate to the home route
    this.go('/login');
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));

    return new Date().getTime() < expiresAt;
  }

  go(to) {
    history.replace(to);
    window.location.href = to;
  }
}
