import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider /*, addLocaleData*/ } from 'react-intl';
import moment from 'moment';
import fiLocale from 'moment/locale/fi';
import enLocale from 'moment/locale/en-gb';

// import 'react-dates/lib/css/_datepicker.css';
import localeData from './localization/strings.json';

//import Auth from './services/Auth';
//import History from './services/History';

import * as serviceWorker from './serviceWorker';

import './assets/base.scss';
import Main from './MainPages/Main';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';

const store = configureStore();
const rootElement = document.getElementById('root');

let language;

if (localStorage.getItem('gpLanguage') == null) {
  localStorage.setItem('gpLanguage', 'en'); // default to en
  language = 'en';
} else {
  language = localStorage.getItem('gpLanguage');
}

const messages = localeData[language] || localeData.fi;
// addLocaleData([...fi, ...en]);
if (language === 'fi') {
  moment.updateLocale('fi', fiLocale);
} else {
  moment.updateLocale('en', enLocale);
}


//const auth = new Auth();

const renderApp = Component => {
  // alert("here is index");
  ReactDOM.render(
    <Provider store={store}>
      <IntlProvider locale={language} key={language} messages={messages}>
        <Component />
      </IntlProvider>
    </Provider>,
    rootElement
  );
};

renderApp(Main);

if (module.hot) {
  module.hot.accept('./MainPages/Main', () => {
    const NextApp = require('./MainPages/Main').default;
    renderApp(NextApp);
  });
}
serviceWorker.unregister();
